
  import { Component, Vue } from "vue-property-decorator";
  import LzButton from "@/components/Button.vue";
  import LzTable from "@/components/Table.vue";
  import LzConfirm from "@/components/Confirm.vue";
  import { namespace } from "vuex-class";
  import { apiShop } from "../api";
  import { parseFile } from "@/utils/parseFile";
  import LzEditorInput from "@/components/EditorInput.vue";

  const auth = namespace("auth");

  @Component({ components: { LzButton, LzTable, LzConfirm, LzEditorInput } })
  export default class ShopCreate extends Vue {
    productId: string | null = null;
    showDeleteModal = false;

    loaded = false;
    statusOptions = {
      enabled: this.$t("projects.create.form.status.options.enabled"),
      disabled: this.$t("projects.create.form.status.options.disabled")
    };
    productForm = {
      ongId: "",
      title: "",
      description: "",
      imageUrlToConvert: [] as any,
      imageUrl: "",
      remark: "",
      stock: "",
      status: "",
      amount: "",
      delivery_time: "",
      discount: "",
      price: "",
      imagesToConvert: [] as any,
      images: [] as any
    };

    @auth.State("id")
    public ongId!: string;

    async mounted() {
      this.productId = this.$route.params.productId;
      if (this.productId) {
        await Promise.all([
          apiShop.getProduct(this.productId).then(({ data }) => {
            this.productForm.ongId = this.ongId;
            this.productForm.title = data.title;
            this.productForm.status = data.active ? "enabled" : "disabled";
            this.productForm.description = data.description;
            this.productForm.imageUrlToConvert = [{ url: data.default_img }];
            this.productForm.price = data.price;
            this.productForm.discount = data.discount;
            this.productForm.amount = data.amount;
            this.productForm.delivery_time = data.delivery_time;
          }),
          apiShop.getProductImages(this.productId).then(({ data }) => {
            this.productForm.imagesToConvert.length = 0;
            data.forEach((d: any) => {
              if (d.img_url.includes("default")) return;

              this.productForm.imagesToConvert.push({ url: d.img_url });
            });
          })
        ]);
      }
      this.loaded = true;
      this.productForm.ongId = this.ongId;
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    async onCreateSubmit(e: any) {
      const imageUrlToBase64 = await parseFile(
        this.productForm.imageUrlToConvert
      );

      const images: any[] = await parseFile(this.productForm.imagesToConvert);

      const body = {
        ...this.productForm,
        imageUrl: imageUrlToBase64[0],
        active: this.productForm.status == "enabled" ? true : false,
        images: Array.isArray(images) ? images : [images],
        imageUrlToConvert: undefined,
        imagesToConvert: undefined
      };
      if (!this.productId) {
        apiShop
          .postProduct(body)
          .then(() => {
            this.$notify({
              type: "success",
              text: this.$tc("shop.create.notifications.productSaved")
            });
            this.$router.push({ name: "shop" });
          })
          .catch((err: any) => {
            this.$notify({
              type: "error",
              text: this.$tc("common.error.generic")
            });
          });
      } else {
        apiShop
          .updateProduct(this.productId, body)
          .then(() => {
            this.$notify({
              type: "success",
              text: this.$tc("shop.create.notifications.productUpdated")
            });
            this.$router.push({ name: "shop" });
          })
          .catch((err: any) => {
            this.$notify({
              type: "error",
              text: this.$tc("common.error.generic")
            });
          });
      }
    }

    onCreateCancel() {
      this.$router.push({ name: "shopRead" });
    }

    confirmDeleteProduct() {
      this.showDeleteModal = true;
    }

    deleteProduct() {
      if (this.productId) {
        apiShop
          .deleteProduct(this.ongId, this.productId)
          .then(() => {
            this.$notify({
              type: "success",
              text: this.$tc("shop.create.notifications.productRemoved")
            });
            this.$router.push({ name: "shopRead" });
          })
          .catch(() => {
            this.$notify({
              type: "error",
              text: this.$tc("common.error.generic")
            });
            this.showDeleteModal = false;
          });
      }
    }
  }
